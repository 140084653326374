import React, { useEffect } from 'react'
import './About.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {



    useEffect(()=>{

      window.scrollTo(0, 0);


      AOS.init({
        duration: 1000,  // Duration of the animation
        easing: 'ease-in-out', // Easing type
        once: true, // Whether the animation happens only once
      });

        document.querySelector("title").textContent = "Total Quality Solution - À PROPOS";
        
        for(var i = 1; i <= 4; i++){
          document.querySelector(".l"+i).style.color = "white";
        }
        document.querySelector(".l3").style.color = "#D0B042";

        document.querySelector("header").style.background = "#2E2D68";
        document.querySelector("footer").style.background = "white";
        document.querySelector("footer").style.paddingTop = "0";
        document.querySelector(".footer_logo").src = "/assets/img/logo_2.png";
        document.querySelector(".footer_menu").style.color = "black";
        document.querySelector(".footer_copyright").style.color = "black";


    })





  return (
    <div>


    <div data-aos="fade-up" className='INSPECTION_title'>
          <h1 className='text_1_about' style={{textTransform:"uppercase"}}>à propos</h1>
          <span></span>
      </div>


    <p data-aos="fade-up" style={{textAlign:"center", marginTop:"2rem"}} className='title_p_conseil_2 text_2_about'>
        Fondée en 2017 à Dakhla, Total Quality Solution est une entreprise spécialisée dans le contrôle qualité et l'inspection des produits de la mer, ainsi que dans le conseil en la matière. Depuis sa création, notre société s'engage à offrir des services d'excellence qui répondent aux normes les plus élevées de l'industrie.
    </p>


    <div className='about_div_1'>

        <img data-aos="fade-right" src='/assets/img/logo_2.png' />

        <p data-aos="fade-left" className='text_3_about'>
        Notre équipe est composée d'experts chevronnés possédant une longue expérience dans le domaine. Grâce à leur savoir-faire et à leur rigueur professionnelle, nous sommes en mesure de fournir à nos clients des rapports précis, détaillés et accompagnés d'analyses statistiques poussées. Ces rapports sont conçus pour offrir une vision claire et exhaustive des produits contrôlés ou inspectés, permettant ainsi à nos clients de prendre des décisions éclairées et d'éviter toute forme de fraude.
        </p>
        
    </div>


    <div className='INSPECTION_title'>
          <h1 data-aos="fade-up" className='text_4_about' style={{textTransform:"uppercase"}}> Notre mission </h1>
      </div>


    <p data-aos="fade-up" style={{textAlign:"center", marginTop:"2rem"}} className='title_p_conseil_2 text_5_about'>
    est simple : garantir à nos clients des produits conformes aux standards de qualité les plus stricts, en les accompagnant avec des solutions sur mesure adaptées à leurs besoins spécifiques. Que ce soit pour un contrôle ponctuel ou un suivi continu, nous nous engageons à être un partenaire de confiance, dédié à la protection de vos intérêts. Chez Total Quality Solution, nous croyons que la transparence, la précision, et l'intégrité sont les piliers d'une collaboration fructueuse et durable. Nous mettons tout en œuvre pour que chaque produit contrôlé respecte les exigences de qualité que vous attendez.
    </p>

    <p data-aos="fade-up" style={{textAlign:"center", marginTop:"2rem", marginBottom:"2rem"}} className='title_p_conseil_2 text_6_about'>
        N'hésitez pas à nous contacter pour discuter de vos besoins spécifiques en matière d'inspection des produits de la mer et découvrir comment nous pouvons vous aider à protéger vos intérêts et ceux de vos clients.
    </p>




    </div>
  )
}

export default About