import React, { useEffect } from 'react'
import './Header.css'
import { Link } from 'react-router-dom'

const Header = () => {

  
  // Detect if the device is mobile
function isMobile() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

if (isMobile()) {
  document.body.style.overflowX = 'hidden'; // Disable horizontal scrolling
}



  let menu = false;
  const openMenu = () => {
    const menuElement = document.querySelector("#menu");
    const barsIcon = document.querySelector(".bars");

    if (!menu) {
      menuElement.style.display = "block"; // Show menu immediately
      menuElement.classList.remove("hide_menu");
      menuElement.classList.add("show_menu");
      barsIcon.src = "/assets/img/close.svg";
      document.querySelector("body").style.overflow = "hidden";
      menu = true;
    } else {
      document.querySelector("body").style.overflow = "auto";
      menuElement.classList.remove("show_menu");
      menuElement.classList.add("hide_menu");
      barsIcon.src = "/assets/img/bars.svg";

      // Hide menu after animation finishes (600ms)
      setTimeout(() => {
        menuElement.style.display = "none";
      }, 600);

      menu = false;
    }
  };


  const hide_menu = () =>{

    const menuElement = document.querySelector("#menu");
    const barsIcon = document.querySelector(".bars");
    
    document.querySelector("body").style.overflow = "auto";
      menuElement.classList.remove("show_menu");
      menuElement.classList.add("hide_menu");
      barsIcon.src = "/assets/img/bars.svg";

      // Hide menu after animation finishes (600ms)
      setTimeout(() => {
        menuElement.style.display = "none";
      }, 600);

      menu = false;

  }


  useEffect(() => {
    
    trans_checker();

    window.scrollTo(0, 0);


  }, []);  // Empty dependency array ensures this runs only once when the component mounts


  const translate_sp = () => {
    localStorage.setItem("langue", "sp");


    const img_whatssap = (selector, src) => {
      const element = document.querySelector(selector);
      if (element) element.src = src;
    };

    img_whatssap(".demande_devis", "/assets/img/home/sp_demande.webp");


    const setTextContents = (texts) => {
      Object.entries(texts).forEach(([selector, text]) => {
        const element = document.querySelector(selector);
        if (element) element.textContent = text;
      });
    };
    
    setTextContents({
      ".view_more_1_home": "Saber más",
      ".view_more_inspection": "Ver más",
      ".btn_engagement": "Compromisos",
    });
  


    const updateTextContent = (selector, text) => {
        const element = document.querySelector(selector);
        if (element) element.textContent = text;
    };

    // Header and menu
    updateTextContent(".l1", "Inicio");
    updateTextContent(".l2", "SERVICIO");
    updateTextContent(".l3", "Acerca de");
    updateTextContent(".l4", "CONTROL");
    updateTextContent(".m_l1", "Inicio");
    updateTextContent(".m_l2", "SERVICIO");
    updateTextContent(".m_l3", "Acerca de");
    updateTextContent(".m_l4", "CONTROL");

    // Footer
    updateTextContent(".footer_home", "Inicio");
    updateTextContent(".footer_news", "Noticias");
    updateTextContent(".footer_about", "Acerca de");
    updateTextContent(".footer_team", "Nuestros equipos");
    updateTextContent(".footer_conditions", "Términos de uso");
    updateTextContent(".footer_copyright", "Copyright @2024 - Diseño por Saed");

    // Home page content
    updateTextContent(".text_1_home", "En Total Quality Solution, la inspección de productos del mar está en el corazón de nuestra misión. Conscientes de la importancia crítica de la seguridad alimentaria y el cumplimiento de los productos, hemos desarrollado un riguroso proceso de inspección que abarca todas las etapas de producción, desde la captura hasta la entrega final.");
    updateTextContent(".text_2_home", "Nuestra experiencia se extiende a toda la cadena de producción, garantizando que cada producto cumpla con los estándares más altos de calidad y seguridad. Participamos desde las primeras etapas de producción para garantizar que las materias primas cumplan con las especificaciones requeridas. Esta vigilancia continúa durante las fases de procesamiento, embalaje y transporte, hasta la entrega al cliente.");
    updateTextContent(".text_3_home", "Productos del Mar");
    updateTextContent(".text_4_home", "Control de Calidad");
    updateTextContent(".text_5_home", "En Total Quality Solution, la calidad no es solo un requisito; es una verdadera cultura que impregna cada paso de nuestro trabajo. Nuestro objetivo es claro: garantizar que cada producto que pase por nuestras manos cumpla no solo con las normas internacionales, sino también con las altas expectativas de sus clientes.");
    updateTextContent(".text_6_home", "Asesoramiento y Apoyo");
    updateTextContent(".text_7_home", "En Total Quality Solution, nuestra experiencia va más allá del control y la inspección. También ofrecemos un servicio integral de asesoramiento y apoyo diseñado para ayudar a nuestros clientes a optimizar sus procesos, mejorar la calidad de sus productos y alcanzar sus objetivos de manera sostenible y eficiente.");
    updateTextContent(".text_8_home", "Nuestro enfoque consultivo");
    updateTextContent(".text_9_home", "Se basa en una comprensión profunda de sus necesidades específicas y un análisis detallado de sus procesos actuales. Trabajamos estrechamente con usted para identificar áreas de mejora, riesgos potenciales y oportunidades de optimización. Con nuestra experiencia en la industria de productos del mar, podemos ofrecer soluciones a medida que cumplen con los estrictos requisitos de su sector.");
    updateTextContent(".text_10_home", "Apoyo para Exportadores e Inversionistas");
    updateTextContent(".text_11_home", "Gracias a nuestra amplia experiencia y excelente reputación en el campo, también ofrecemos apoyo especializado para exportadores e inversionistas que deseen establecer proyectos o desarrollar inversiones en el sector de productos del mar. Le ayudamos a navegar por las complejidades regulatorias, identificar oportunidades de mercado e implementar estrategias efectivas para maximizar el retorno de inversión. Nuestro profundo conocimiento de los mercados locales e internacionales garantiza un apoyo valioso a lo largo de su trayectoria.");
    updateTextContent(".text_12_home", "Nuestros servicios de consultoría abarcan desde la optimización de procesos de producción hasta la gestión de riesgos, incluyendo la formación de sus equipos en nuevas prácticas de control de calidad. Cada recomendación que hacemos se basa en datos precisos y experiencia concreta, garantizando resultados tangibles y sostenibles.");
    updateTextContent(".text_13_home", "Apoyo Personalizado");
    updateTextContent(".text_14_home", "Al elegir Total Quality Solution, también se beneficia de un apoyo personalizado y un seguimiento continuo. Adaptamos nuestros servicios a las necesidades cambiantes de su negocio, asegurando el éxito de cada proyecto. Contratar a Total Quality Solution para sus necesidades de consultoría y apoyo significa elegir un socio comprometido con su éxito. Contáctenos hoy mismo para hablar sobre sus desafíos y descubrir cómo podemos ayudarle a superarlos con soluciones a medida, adaptadas a sus objetivos y ambiciones.");



    // page services

    updateTextContent(".text_1_service", "SERVICIO");
    updateTextContent(".text_2_service", "Gracias a nuestro alto nivel de profesionalismo, nuestros equipos realizan inspecciones meticulosas, utilizando tecnologías de vanguardia que permiten detectar cualquier no conformidad. Nuestros informes son detallados, claros y acompañados de soluciones tecnológicas eficaces, brindándote total visibilidad sobre el estado y la calidad de tus productos en cada etapa del proceso.");
    updateTextContent(".text_3_service", "Al elegir Total Quality Solution, no solo obtiene un servicio de inspección impecable, sino también un socio comprometido en preservar la calidad de sus productos y garantizar su conformidad con los requisitos regulatorios y comerciales. Nuestros informes le brindan la tranquilidad necesaria para que pueda concentrarse en su actividad principal, mientras tiene la seguridad de que sus productos cumplen con los más altos estándares de calidad.");
    updateTextContent(".text_4_service", "No dude en contactarnos para discutir sus necesidades específicas en cuanto a la inspección de productos del mar y descubrir cómo podemos ayudarle a proteger sus intereses y los de sus clientes.");


    // about page

    updateTextContent(".text_1_about", "Acerca de");
    updateTextContent(".text_2_about", "Fundada en 2017 en Dakhla, Total Quality Solution es una empresa especializada en el control de calidad y la inspección de productos del mar, así como en la consultoría en este ámbito. Desde su creación, nuestra empresa se compromete a ofrecer servicios de excelencia que cumplen con los más altos estándares de la industria.");
    updateTextContent(".text_3_about", "Nuestro equipo está compuesto por expertos experimentados con una amplia trayectoria en el campo. Gracias a su conocimiento y rigor profesional, somos capaces de proporcionar a nuestros clientes informes precisos, detallados y respaldados por análisis estadísticos avanzados. Estos informes están diseñados para ofrecer una visión clara y exhaustiva de los productos controlados o inspeccionados, permitiendo así a nuestros clientes tomar decisiones informadas y evitar cualquier forma de fraude.");
    updateTextContent(".text_4_about", "Nuestra Misión");
    updateTextContent(".text_5_about", "Nuestra misión es simple: garantizar a nuestros clientes productos que cumplan con los estándares de calidad más estrictos, ofreciéndoles soluciones personalizadas adaptadas a sus necesidades específicas. Ya sea para un control puntual o un seguimiento continuo, nos comprometemos a ser un socio de confianza, dedicado a proteger sus intereses. En Total Quality Solution, creemos que la transparencia, la precisión y la integridad son los pilares de una colaboración exitosa y duradera. Nos esforzamos para que cada producto inspeccionado cumpla con los requisitos de calidad que usted espera.");
    updateTextContent(".text_6_about", "No dude en ponerse en contacto con nosotros para hablar sobre sus necesidades específicas en la inspección de productos del mar y descubrir cómo podemos ayudarle a proteger sus intereses y los de sus clientes.");



    // Control

      updateTextContent(".text_1_control", "Compromiso de Calidad");
      updateTextContent(".text_2_control", "Desde la recepción de las materias primas, implementamos un control riguroso para asegurarnos de que cada componente cumpla con los criterios de calidad establecidos. Esta atención se mantiene a lo largo de todo el proceso de producción, con verificaciones regulares y pruebas exhaustivas en cada etapa clave.");
      updateTextContent(".text_3_control", "Nuestro enfoque proactivo");
      updateTextContent(".text_4_control", "Nuestro enfoque proactivo permite identificar rápidamente las anomalías y aplicar medidas correctivas antes de que afecten la calidad final del producto. Gracias al uso de herramientas tecnológicas avanzadas y a la experiencia de nuestro equipo, podemos garantizar una trazabilidad completa y una transparencia total en cada fase del proceso. Creemos firmemente que el control de calidad no se limita a detectar los defectos, sino que debe ser también un motor de mejora continua. Por eso, nuestros informes van más allá de simples constataciones: incluyen análisis detallados, datos estadísticos precisos y recomendaciones personalizadas para optimizar sus procesos de producción.");
      updateTextContent(".text_5_control", "Trabajar con Total Quality Solution es elegir un socio comprometido que comprende la importancia de la calidad para su empresa y que hace todo lo posible para que sus productos siempre cumplan con las expectativas más altas.");
      updateTextContent(".text_6_control", "Le invitamos a descubrir cómo nuestros servicios de control de calidad pueden reforzar la fiabilidad de sus productos y ayudarle a mantener la confianza de sus clientes. Para obtener más información, no dude en ponerse en contacto con nosotros. Estamos aquí para acompañarle en cada paso, con soluciones adaptadas a sus necesidades específicas.");

  };





const translate_fr = () => {
  localStorage.setItem("langue", "fr");

  const img_whatssap = (selector, src) => {
    const element = document.querySelector(selector);
    if (element) element.src = src;
  };

  img_whatssap(".demande_devis", "/assets/img/home/demande_devis.webp");

  const setTextContents = (texts) => {
    Object.entries(texts).forEach(([selector, text]) => {
      const element = document.querySelector(selector);
      if (element) element.textContent = text;
    });
  };
  
  setTextContents({
    ".view_more_1_home": "En savoir plus",
    ".view_more_inspection": "Voire Plus",
    ".btn_engagement": "Engagements",
  });


  // Helper function to update text content safely
  const updateTextContent = (selector, text) => {
      const element = document.querySelector(selector);
      if (element) element.textContent = text;
  };

  // Header and menu
  updateTextContent(".l1", "ACCUEIL");
  updateTextContent(".l2", "SERVICE");
  updateTextContent(".l3", "À PROPOS");
  updateTextContent(".l4", "CONTRÔLE");

  // Mobile translate
  updateTextContent(".m_l1", "ACCUEIL");
  updateTextContent(".m_l2", "SERVICE");
  updateTextContent(".m_l3", "À PROPOS");
  updateTextContent(".m_l4", "CONTRÔLE");

  // Footer data
  updateTextContent(".footer_home", "Accueil");
  updateTextContent(".footer_news", "Nouvelles");
  updateTextContent(".footer_about", "À propos");
  updateTextContent(".footer_team", "Nos équipes");
  updateTextContent(".footer_conditions", "Conditions D'utilisation");
  updateTextContent(".footer_copyright", "Copyright @2024 - Designed by Saed");

  // Home page content
  updateTextContent(".text_2_home", "Notre expertise s'étend tout au long de la chaîne de production, garantissant que chaque produit respecte les normes les plus élevées de qualité et de sécurité. Nous intervenons dès les premières étapes de la production pour garantir que les matières premières respectent les spécifications requises. Cette vigilance se poursuit tout au long des phases de transformation, d'emballage et de transport, jusqu'à la livraison au client.");
  updateTextContent(".text_3_home", "Produits de la mer");
  updateTextContent(".text_4_home", "Contrôle qualité");
  updateTextContent(".text_5_home", "Chez Total Quality Solution, la qualité n'est pas seulement une exigence ; c'est une véritable culture qui imprègne chaque étape de notre travail. Notre objectif est clair : garantir que chaque produit qui passe entre nos mains respecte non seulement les normes internationales, mais aussi les attentes élevées de vos clients.");
  updateTextContent(".text_6_home", "Conseil et accompagnement");
  updateTextContent(".text_7_home", "Chez Total Quality Solution, notre expertise va au-delà du simple contrôle et de l'inspection. Nous proposons également un service complet de conseil et d'accompagnement conçu pour aider nos clients à optimiser leurs processus, améliorer la qualité de leurs produits et atteindre leurs objectifs de manière durable et efficace.");
  updateTextContent(".text_8_home", "Notre approche consultative");
  updateTextContent(".text_9_home", "Elle repose sur une compréhension approfondie de vos besoins spécifiques et une analyse détaillée de vos processus actuels. Nous travaillons en étroite collaboration avec vous pour identifier les points d'amélioration, les risques potentiels et les opportunités d'optimisation. Grâce à notre expertise dans l'industrie des produits de la mer, nous sommes en mesure de proposer des solutions sur mesure qui répondent aux exigences strictes de votre secteur.");
  updateTextContent(".text_10_home", "Accompagnement des exportateurs et investisseurs");
  updateTextContent(".text_11_home", "Grâce à notre vaste expérience et à notre excellente réputation dans le domaine, nous offrons également un accompagnement spécialisé pour les exportateurs et les investisseurs souhaitant établir leurs projets ou développer leurs investissements dans le secteur des produits de la mer. Nous vous aidons à naviguer dans les complexités réglementaires, à identifier les opportunités de marché et à mettre en place des stratégies efficaces pour maximiser le retour sur investissement. Notre connaissance approfondie des marchés locaux et internationaux vous garantit un soutien précieux tout au long de votre parcours.");
  updateTextContent(".text_12_home", "Nos services de conseil s'étendent de l'optimisation des processus de production à la gestion des risques, en passant par la formation de vos équipes aux nouvelles pratiques de contrôle qualité. Chaque recommandation que nous faisons est fondée sur des données précises et une expérience concrète, vous assurant des résultats tangibles et durables.");
  updateTextContent(".text_13_home", "Accompagnement personnalisé");
  updateTextContent(".text_14_home", "En choisissant Total Quality Solution, vous bénéficiez également d’un accompagnement personnalisé et d’un suivi continu. Nous ajustons nos services en fonction de l’évolution des besoins de votre entreprise, en vous assurant que chaque projet est mené à bien avec succès. Faire appel à Total Quality Solution pour vos besoins en conseil et accompagnement, c’est choisir un partenaire engagé dans votre réussite. Contactez-nous dès aujourd’hui pour discuter de vos défis et découvrir comment nous pouvons vous aider à les surmonter avec des solutions sur mesure, adaptées à vos objectifs et à vos ambitions.");
  updateTextContent(".text_1_home", "Chez Total Quality Solution, l'inspection des produits de la mer est au cœur de notre mission. Conscients de l'importance cruciale de la sécurité alimentaire et de la conformité des produits, nous avons développé un processus d'inspection rigoureux qui couvre chaque étape de la production, de la capture à la livraison finale.");


  // service

  updateTextContent(".text_1_service", "SERVICE");
  updateTextContent(".text_2_service", "Grâce à notre haut niveau de professionnalisme, nos équipes réalisent des inspections méticuleuses, en utilisant des technologies de pointe qui permettent de détecter toute non-conformité. Nos rapports sont détaillés, clairs et accompagnés de solutions technologiques efficaces, vous offrant une visibilité totale sur l'état et la qualité de vos produits à chaque étape du processus.");
  updateTextContent(".text_3_service", "En choisissant Total Quality Solution, vous bénéficiez non seulement d'un service d'inspection irréprochable, mais aussi d'un partenaire engagé à préserver la qualité de vos produits et à garantir leur conformité avec les exigences réglementaires et commerciales. Nos rapports vous apportent la tranquillité d'esprit nécessaire pour que vous puissiez vous concentrer sur votre cœur de métier, tout en étant assuré que vos produits répondent aux plus hauts standards de qualité.");
  updateTextContent(".text_4_service", "N'hésitez pas à nous contacter pour discuter de vos besoins spécifiques en matière d'inspection des produits de la mer et découvrir comment nous pouvons vous aider à protéger vos intérêts et ceux de vos clients.");


  // about

  updateTextContent(".text_1_about", "À propos");
  updateTextContent(".text_3_about", "Notre équipe est composée d'experts chevronnés possédant une longue expérience dans le domaine. Grâce à leur savoir-faire et à leur rigueur professionnelle, nous sommes en mesure de fournir à nos clients des rapports précis, détaillés et accompagnés d'analyses statistiques poussées. Ces rapports sont conçus pour offrir une vision claire et exhaustive des produits contrôlés ou inspectés, permettant ainsi à nos clients de prendre des décisions éclairées et d'éviter toute forme de fraude.");
  updateTextContent(".text_4_about", "Notre Mission");
  updateTextContent(".text_5_about", "Notre mission est simple : garantir à nos clients des produits conformes aux standards de qualité les plus stricts, en les accompagnant avec des solutions sur mesure adaptées à leurs besoins spécifiques. Que ce soit pour un contrôle ponctuel ou un suivi continu, nous nous engageons à être un partenaire de confiance, dédié à la protection de vos intérêts. Chez Total Quality Solution, nous croyons que la transparence, la précision, et l'intégrité sont les piliers d'une collaboration fructueuse et durable. Nous mettons tout en œuvre pour que chaque produit contrôlé respecte les exigences de qualité que vous attendez.");
  updateTextContent(".text_6_about", "N'hésitez pas à nous contacter pour discuter de vos besoins spécifiques en matière d'inspection des produits de la mer et découvrir comment nous pouvons vous aider à protéger vos intérêts et ceux de vos clients.");
  updateTextContent(".text_2_about", "Fondée en 2017 à Dakhla, Total Quality Solution est une entreprise spécialisée dans le contrôle qualité et l'inspection des produits de la mer, ainsi que dans le conseil en la matière. Depuis sa création, notre société s'engage à offrir des services d'excellence qui répondent aux normes les plus élevées de l'industrie.");


  // control

  updateTextContent(".text_1_control", "Engagement Qualité");
  updateTextContent(".text_3_control", "Notre approche proactive");
  updateTextContent(".text_4_control", "Notre approche proactive permet d'identifier rapidement les anomalies et de mettre en œuvre des mesures correctives avant qu'elles n'affectent la qualité finale du produit. Grâce à l'utilisation d'outils technologiques avancés et à l'expertise de notre équipe, nous pouvons garantir une traçabilité complète et une transparence totale à chaque étape du processus. Nous croyons fermement que le contrôle qualité ne se limite pas à détecter les défauts, mais doit aussi être un moteur d'amélioration continue. C'est pourquoi nos rapports vont au-delà des simples constatations : ils incluent des analyses détaillées, des données statistiques précises et des recommandations personnalisées pour optimiser vos processus de production.");
  updateTextContent(".text_5_control", "Travailler avec Total Quality Solution, c'est choisir un partenaire engagé qui comprend l'importance de la qualité pour votre entreprise et qui met tout en œuvre pour que vos produits soient toujours conformes aux attentes les plus élevées.");
  updateTextContent(".text_6_control", "Nous vous invitons à découvrir comment nos services de contrôle qualité peuvent renforcer la fiabilité de vos produits et vous aider à maintenir la confiance de vos clients. Pour en savoir plus, n'hésitez pas à nous contacter. Nous sommes là pour vous accompagner à chaque étape, avec des solutions adaptées à vos besoins spécifiques.");
  updateTextContent(".text_2_control", "Dès la réception des matières premières, nous mettons en place un contrôle rigoureux pour nous assurer que chaque composant répond aux critères de qualité établis. Cette attention se poursuit tout au long du processus de production, avec des vérifications régulières et des tests approfondis à chaque étape clé.");



};




  const translate_us = () => {
    localStorage.setItem("langue", "us");

    const img_whatssap = (selector, src) => {
      const element = document.querySelector(selector);
      if (element) element.src = src;
    };
   
    img_whatssap(".demande_devis", "/assets/img/home/en_demande.webp");


  const setTextContents = (texts) => {
    Object.entries(texts).forEach(([selector, text]) => {
      const element = document.querySelector(selector);
      if (element) element.textContent = text;
    });
  };
  
  setTextContents({
    ".view_more_1_home": "Learn more",
    ".view_more_inspection": "See more",
    ".btn_engagement": "Commitments",
  });


    const translateElement = (selector, text) => {
        const element = document.querySelector(selector);
        if (element) element.textContent = text;
    };

    // Header translations
    translateElement(".l1", "Home");
    translateElement(".l2", "SERVICE");
    translateElement(".l3", "About");
    translateElement(".l4", "CONTROLE");

    // Mobile translations
    translateElement(".m_l1", "Home");
    translateElement(".m_l2", "SERVICE");
    translateElement(".m_l3", "About");
    translateElement(".m_l4", "CONTROLE");

    // Footer translations
    translateElement(".footer_home", "Home");
    translateElement(".footer_news", "News");
    translateElement(".footer_about", "About");
    translateElement(".footer_team", "Our Teams");
    translateElement(".footer_conditions", "Terms of Use");
    translateElement(".footer_copyright", "Copyright @2024 - Designed by Saed");

    // Content home translations
    translateElement(
        ".text_1_home",
        "At Total Quality Solution, seafood inspection is at the heart of our mission. Aware of the critical importance of food safety and product compliance, we have developed a rigorous inspection process that covers every stage of production, from capture to final delivery."
    );
    translateElement(
        ".text_2_home",
        "Our expertise extends throughout the entire production chain, ensuring that each product meets the highest standards of quality and safety. We are involved from the earliest stages of production to ensure that raw materials comply with the required specifications. This vigilance continues throughout the processing, packaging, and transportation phases, all the way to delivery to the customer."
    );
    translateElement(".text_3_home", "Seafood Products");
    translateElement(".text_4_home", "Quality control");
    translateElement(
        ".text_5_home",
        "At Total Quality Solution, quality is not just a requirement; it is a true culture that permeates every step of our work. Our goal is clear: to ensure that every product that passes through our hands meets not only international standards but also the high expectations of your customers."
    );
    translateElement(".text_6_home", "Advice and Support");
    translateElement(
        ".text_7_home",
        "At Total Quality Solution, our expertise goes beyond just control and inspection. We also offer a comprehensive advisory and support service designed to help our clients optimize their processes, improve the quality of their products, and achieve their goals in a sustainable and efficient manner."
    );
    translateElement(".text_8_home", "Our consultative approach");
    translateElement(
        ".text_9_home",
        "It is based on a deep understanding of your specific needs and a detailed analysis of your current processes. We work closely with you to identify areas for improvement, potential risks, and opportunities for optimization. With our expertise in the seafood industry, we are able to offer tailor-made solutions that meet the strict requirements of your sector."
    );
    translateElement(".text_10_home", "support for exporters and investors");
    translateElement(
        ".text_11_home",
        "Thanks to our extensive experience and excellent reputation in the field, we also offer specialized support for exporters and investors looking to establish projects or develop investments in the seafood sector. We help you navigate regulatory complexities, identify market opportunities, and implement effective strategies to maximize return on investment. Our in-depth knowledge of both local and international markets ensures valuable support throughout your journey."
    );
    translateElement(
        ".text_12_home",
        "Our consulting services range from optimizing production processes to risk management, including training your teams in new quality control practices. Every recommendation we make is based on accurate data and concrete experience, ensuring tangible and sustainable results."
    );
    translateElement(".text_13_home", "Personalized Support");
    translateElement(
        ".text_14_home",
        "By choosing Total Quality Solution, you also benefit from personalized support and continuous follow-up. We tailor our services to the evolving needs of your business, ensuring the successful completion of every project. Engaging Total Quality Solution for your consulting and support needs means choosing a partner committed to your success. Contact us today to discuss your challenges and discover how we can help you overcome them with tailored solutions that align with your goals and ambitions."
    );



    // translate service 

    translateElement(".text_1_service", "SERVICE");
    translateElement(".text_3_service", "By choosing Total Quality Solution, you not only get impeccable inspection service, but also a partner committed to preserving the quality of your products and ensuring their compliance with regulatory and commercial requirements. Our reports give you the peace of mind necessary to focus on your core business, while ensuring that your products meet the highest quality standards.");
    translateElement(".text_4_service", "Feel free to contact us to discuss your specific needs regarding seafood product inspection and discover how we can help protect your interests and those of your clients.");
    translateElement(".text_2_service", "Thanks to our high level of professionalism, our teams carry out meticulous inspections using cutting-edge technologies that detect any non-compliance. Our reports are detailed, clear, and accompanied by effective technological solutions, providing you with complete visibility on the status and quality of your products at every stage of the process.");


    // about


    translateElement(".text_1_about", "About");
    translateElement(".text_3_about", "Our team consists of seasoned experts with extensive experience in the field. Thanks to their expertise and professional rigor, we are able to provide our clients with accurate, detailed reports supported by advanced statistical analyses. These reports are designed to offer a clear and comprehensive view of the inspected or controlled products, enabling our clients to make informed decisions and avoid any form of fraud.");
    translateElement(".text_4_about", "Our Mission");
    translateElement(".text_5_about", "Our mission is simple: to ensure that our clients receive products that comply with the strictest quality standards, offering them tailored solutions adapted to their specific needs. Whether for a one-time inspection or ongoing monitoring, we are committed to being a trusted partner dedicated to protecting your interests. At Total Quality Solution, we believe that transparency, precision, and integrity are the pillars of a successful and lasting collaboration. We strive to ensure that every inspected product meets the quality requirements you expect.");
    translateElement(".text_6_about", "Do not hesitate to contact us to discuss your specific needs regarding seafood product inspection and to discover how we can help you protect your interests and those of your clients.");
    translateElement(".text_2_about", "Founded in 2017 in Dakhla, Total Quality Solution is a company specializing in quality control and inspection of seafood products, as well as consulting in this field. Since its inception, our company has been committed to providing excellent services that meet the highest industry standards.");


    // control

    translateElement(".text_1_control", "Quality Commitment");
    translateElement(".text_2_control", "From the reception of raw materials, we implement rigorous control to ensure that each component meets the established quality criteria. This attention continues throughout the entire production process, with regular checks and thorough testing at every key stage.");
    translateElement(".text_3_control", "Our Proactive Approach");
    translateElement(".text_4_control", "Our proactive approach allows us to quickly identify anomalies and apply corrective measures before they affect the final product quality. Thanks to the use of advanced technological tools and the expertise of our team, we can guarantee complete traceability and total transparency at each stage of the process. We firmly believe that quality control is not limited to detecting defects, but must also be a driver of continuous improvement. This is why our reports go beyond simple observations: they include detailed analyses, precise statistical data, and tailored recommendations to optimize your production processes.");
    translateElement(".text_5_control", "Working with Total Quality Solution means choosing a committed partner who understands the importance of quality for your business and does everything possible to ensure that your products always meet the highest expectations.");
    translateElement(".text_6_control", "We invite you to discover how our quality control services can strengthen the reliability of your products and help you maintain your clients' trust. For more information, feel free to contact us. We are here to guide you every step of the way, with solutions tailored to your specific needs.");
    


};


function trans_checker(){

setTimeout(()=>{
  const langue = localStorage.getItem("langue");

  if (langue === "fr") {
    translate_fr();
  } else if (langue === "sp") {
    translate_sp();
  } else if (langue === "us") {
    translate_us();
  }else {
  // If no language is set, default to French and set it in localStorage
  localStorage.setItem("langue", "fr");
  translate_fr();  // Apply the default language translation
}

}, 10)

}




  return (

    <>

    <header className='Header'>

        <Link to={'/'}>
            <img src='/assets/img/logo.svg' alt='Total Quality Solution LOGO' className='logo' />
        </Link>


        <div className='menu'>

            <Link to={''}><h2 onClick={() => trans_checker()} className='l1'>ACCUEIL</h2></Link>

            <Link to={'service'}><h2 onClick={() => trans_checker()} className='l2'>SERVICE</h2></Link>

            <Link to={'propos'}><h2 onClick={() => trans_checker()} className='l3'>À PROPOS</h2></Link>

            <Link to={'controle'}><h2 onClick={() => trans_checker()} className='l4'>CONTRÔLE</h2></Link>

        </div>


        <div className='flags'>

            <Link><img onClick={() => translate_sp()} src='/assets/img/flags/es.png' alt='Total Quality Solution LOGO' className='spain' /></Link>
            <Link><img onClick={() => translate_fr()} src='/assets/img/flags/fr.png' alt='Total Quality Solution LOGO' className='french' /></Link>
            <Link><img onClick={() => translate_us()} src='/assets/img/flags/us.png' alt='Total Quality Solution LOGO' className='usa' /></Link>

        </div>

        <img onClick={() => openMenu()} src='/assets/img/bars.svg' alt='' className='bars' />


    </header>


    <div className='menu_mobile' id='menu'>

        <Link to={''}><h2 onClick={() => {hide_menu();trans_checker()}} className='m_l1'>ACCUEIL</h2></Link>

        <Link to={'service'}><h2 onClick={() => {hide_menu();trans_checker()}} className='m_l2'>SERVICE</h2></Link>

        <Link to={'propos'}><h2 onClick={() => {hide_menu();trans_checker()}} className='m_l3'>À PROPOS</h2></Link>

        <Link to={'controle'}><h2 onClick={() => {hide_menu();trans_checker()}} className='m_l4'>CONTRÔLE</h2></Link>




        <div className='flags'>

        <Link><img onClick={() => {translate_sp(); hide_menu()}} src='/assets/img/flags/es.png' alt='Total Quality Solution LOGO' className='spain' /></Link>
        <Link><img onClick={() => {translate_fr(); hide_menu()}} src='/assets/img/flags/fr.png' alt='Total Quality Solution LOGO' className='french' /></Link>
        <Link><img onClick={() => {translate_us(); hide_menu()}} src='/assets/img/flags/us.png' alt='Total Quality Solution LOGO' className='usa' /></Link>

        </div>


        <Link to={'/'}>
            <img src='/assets/img/logo_2.png' alt='Total Quality Solution LOGO' className='logo' />
        </Link>

        

    </div>
    
    </>

  )
}

export default Header