import React, { useEffect } from 'react'
import './Service.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Service = () => {



    useEffect(()=>{

      window.scrollTo(0, 0);

      AOS.init({
        duration: 1000,  // Duration of the animation
        easing: 'ease-in-out', // Easing type
        once: true, // Whether the animation happens only once
      });

        document.querySelector("title").textContent = "Total Quality Solution - Service";
        
        for(var i = 1; i <= 4; i++){
          document.querySelector(".l"+i).style.color = "white";
        }
        document.querySelector(".l2").style.color = "#D0B042";

        document.querySelector("header").style.background = "#2E2D68";
        document.querySelector("footer").style.background = "white";
        document.querySelector("footer").style.paddingTop = "0";
        document.querySelector(".footer_logo").src = "/assets/img/logo_2.png";
        document.querySelector(".footer_menu").style.color = "black";
        document.querySelector(".footer_copyright").style.color = "black";


    })





  return (
    <div>


    <div data-aos="fade-up" className='INSPECTION_title'>
          <h1 className='text_1_service'>SERVICE</h1>
          <span></span>
      </div>


    <p data-aos="fade-up" style={{textAlign:"center", marginTop:"2rem"}} className='title_p_conseil_2 text_2_service'>
        Grâce à notre professionnalisme de haut niveau, nos équipes réalisent des inspections méticuleuses, en utilisant des technologies de pointe qui permettent de détecter toute non-conformité. Nos rapports sont détaillés, clairs et accompagnés de solutions technologiques efficaces, vous offrant une visibilité totale sur l'état et la qualité de vos produits à chaque étape du processus.
    </p>


    <div className='service_div_1'>
        <img data-aos="fade-right" src='/assets/img/service/ser_img_1.png' alt=''/>
        <img data-aos="fade-up" src='/assets/img/service/ser_img_2.png' alt=''/>
        <img data-aos="fade-left" src='/assets/img/service/ser_img_3.png' alt=''/>
    </div>


    <p data-aos="fade-up" style={{textAlign:"center", marginTop:"3rem"}} className='title_p_conseil_2 text_3_service'>
    En choisissant Total Quality Solution, vous bénéficiez non seulement d'un service d'inspection irréprochable, mais aussi d'un partenaire engagé à préserver la qualité de vos produits et à garantir leur conformité avec les exigences réglementaires et commerciales. Nos rapports vous apportent la tranquillité d'esprit nécessaire pour que vous puissiez vous concentrer sur votre cœur de métier, tout en étant assuré que vos produits répondent aux plus hauts . standards de qualité.
    </p>

    <p data-aos="fade-up" style={{textAlign:"center", marginTop:"3rem", marginBottom:"3rem"}} className='title_p_conseil_2 text_4_service'>
        N'hésitez pas à nous contacter pour discuter de vos besoins spécifiques en matière d'inspection des produits de la mer et découvrir comment nous pouvons vous aider à protéger vos intérêts et ceux de vos clients.
    </p>



    </div>
  )
}

export default Service