import React, { useEffect } from 'react'
import './Controle.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Controle = () => {

    useEffect(()=>{

      window.scrollTo(0, 0);


      AOS.init({
        duration: 1000,  // Duration of the animation
        easing: 'ease-in-out', // Easing type
        once: true, // Whether the animation happens only once
      });

        document.querySelector("title").textContent = "Total Quality Solution - CONTRÔLE";
        
        for(var i = 1; i <= 4; i++){
          document.querySelector(".l"+i).style.color = "white";
        }
        document.querySelector(".l4").style.color = "#D0B042";

        document.querySelector("header").style.background = "#2E2D68";
        document.querySelector("footer").style.background = "white";
        document.querySelector("footer").style.paddingTop = "0";
        document.querySelector(".footer_logo").src = "/assets/img/logo_2.png";
        document.querySelector(".footer_menu").style.color = "black";
        document.querySelector(".footer_copyright").style.color = "black";


    })


  return (

    <div className='Engagement_Qualite'>
    
    <div  data-aos="fade-up"  className='INSPECTION_title '>
          <h1 className='text_1_control' style={{textTransform:"uppercase"}}> Engagement Qualité </h1>
          <span></span>
      </div>


    <p  data-aos="fade-up"  style={{textAlign:"left", marginTop:"2rem"}} className='title_p_conseil_2 text_2_control'>
    Dès la réception des matières premières, nous mettons en place un contrôle rigoureux pour nous assurer que chaque composant répond aux critères de qualité définis. Cette attention se poursuit tout au long du processus de production, avec des vérifications régulières et des tests approfondis à chaque étape clé. 
    </p>



    <div  className='INSPECTION_title'>
          <h1  data-aos="fade-up"  className='text_3_control' style={{textTransform:"uppercase"}}> Notre approche proactive </h1>
      </div>


    <p  data-aos="fade-up"  style={{textAlign:"left", marginTop:"3rem"}} className='title_p_conseil_2 text_4_control'>
      permet d'identifier rapidement les anomalies et de mettre en œuvre des mesures correctives avant qu'elles n'affectent la qualité finale du produit. Grâce à l'utilisation d'outils technologiques avancés et à l'expertise de notre équipe, nous sommes en mesure de garantir une traçabilité complète et une transparence totale à chaque phase du processus.Nous croyons fermement que le contrôle qualité ne se limite pas à détecter les défauts, mais qu'il doit aussi être un vecteur d'amélioration continue. C'est pourquoi nos rapports vont au-delà des simples constats : ils incluent des analyses détaillées, des données statistiques précises, et des recommandations sur mesure pour optimiser vos processus de production.
    </p>


    <div className='starts_control'></div>

    <p  data-aos="fade-up"  style={{textAlign:"left", marginTop:"3rem"}} className='title_p_conseil_2 text_5_control'>
    Travailler avec Total Quality Solution, c'est choisir un partenaire engagé qui comprend l'importance de la qualité pour votre entreprise et qui met tout en œuvre pour que vos produits soient toujours conformes aux attentes les plus élevées.
    </p>


    <p  data-aos="fade-up"  style={{textAlign:"left", marginTop:"3rem"}} className='title_p_conseil_2 text_6_control'>
    Nous vous invitons à découvrir comment nos services de contrôle qualité peuvent renforcer la fiabilité de vos produits et vous aider à maintenir la confiance de vos clients. Pour en savoir plus, n'hésitez pas à nous contacter. Nous sommes là pour vous accompagner à chaque étape, avec des solutions adaptées à vos besoins spécifiques.
    </p>



    </div>

  )
}

export default Controle