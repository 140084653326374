
import React, { useEffect } from 'react'
import './Home.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Home = () => {
  
  useEffect(()=>{

    window.scrollTo(0, 0);


    AOS.init({
      duration: 1000,  // Duration of the animation
      easing: 'ease-in-out', // Easing type
      once: true, // Whether the animation happens only once
    });

    document.querySelector("title").textContent = "Total Quality Solution - ACCUEIL";
    

    for(var i = 1; i <= 4; i++){
      document.querySelector(".l"+i).style.color = "white";
    }
    document.querySelector(".l1").style.color = "#D0B042";

    document.querySelector("header").style.background = "transparent";
    document.querySelector("footer").style.background = "#2E2D68";
    document.querySelector("footer").style.paddingTop = "2rem";
    document.querySelector(".footer_logo").src = "/assets/img/logo_1.png";
    document.querySelector(".footer_menu").style.color = "white";
    document.querySelector(".footer_copyright").style.color = "white";

    
  })


  const go_whatsapp = () =>{
    window.location.href = "https://wa.me/+2120605599674";
  }
  

  return (
    <>
    <h1 className='title_seo'>TOTAL QUALITY SOLUTION</h1>
    <div className='home_app'>

      <Link to={"/propos"}><button className='view_more_1_home'>En savoir plus</button></Link>

      <img onClick={() => go_whatsapp()} src='/assets/img/home/demande_devis.webp' alt='tqsmorocco demande devis' className='demande_devis' />

      <div className='home_img_1'>
        
          <img src='/assets/img/home/text_home_1.svg' alt='tqsmorocco' className='text_home_1' />

      </div>
      
    </div>

    <div className='home_INSPECTION'>

      <div data-aos="fade-up" className='INSPECTION_title'>
          <h1>INSPECTION</h1>
          <span></span>
      </div>

      
      <div className='home_INSPECTION_Section'>

        <div data-aos="fade-right" className='pro_de_mer'>
            <h1 className='text_3_home'>Produits de la Mer</h1>
        </div>



        <div className='pro_de_mer_text'>

          <p data-aos="fade-up" className='pro_de_mer_text_p_1 text_1_home'>
            Chez <b>Total Quality Solution</b>, l'inspection des produits de la mer est au cœur de nos missions. Conscients des enjeux liés à la sécurité alimentaire et à la conformité des produits, nous avons développé un processus d'inspection rigoureux qui couvre toutes les étapes de production, de la capture à la livraison finale.
          </p>

          <p data-aos="fade-up" className='pro_de_mer_text_p_2 text_2_home'>
            Notre expertise s'étend à l'ensemble de la chaîne de production, garantissant que chaque produit respecte les standards les plus stricts de qualité et de sécurité. Nous intervenons dès les premières phases de la production pour nous assurer que les matières premières sont conformes aux spécifications requises. Cette vigilance se poursuit tout au long du processus de transformation, de conditionnement et de transport, jusqu'à la livraison chez le client.
          </p>

          <Link to={"/service"}><button data-aos="fade-up" className='view_more_inspection'>Voire Plus</button></Link>

          
        </div>
      
        

      </div>




      <div className='home_Controle_qualite'>

        <div className='home_bg_controle_qu'>

          <h1 data-aos="fade-up" className='text_4_home'>Contrôle qualité</h1>

          <p data-aos="fade-up" className='text_5_home'>
            Chez <b>Total Quality Solution</b>, la qualité n'est pas simplement une exigence, c'est une véritable culture qui imprègne chaque étape de notre travail. Notre objectif est clair : garantir que chaque produit qui passe entre nos mains respecte non seulement les normes internationales, mais aussi les attentes élevées de vos clients.
          </p>

          <Link to={"/controle"}><button className='btn_engagement'>Engagement</button></Link>


        </div>


      </div>

      

      <div className='home_Conseil'>

        <div className='home_Conseil_div_1'>

          <div className='div_1_co'>

            <h1 data-aos="fade-up" className='text_6_home'>Conseil et Accompagnement </h1>

            <p data-aos="fade-up" className='text_7_home'>
            Chez <b>Total Quality Solution</b>, notre expertise ne se limite pas au contrôle et à l’inspection. Nous proposons également un service complet de conseil et d’accompagnement, conçu pour aider nos clients à optimiser leurs processus, à améliorer la qualité de leurs produits et à atteindre leurs objectifs de manière durable et efficace.
            </p>

          </div>

          <img data-aos="fade-left" src='/assets/img/home/serdine.webp' className='serdine_home'/>

        </div>



        <h1 className='title_conseil_2 text_8_home ' data-aos="fade-up">Notre approche consultative</h1>

        <p className='title_p_conseil_2 text_9_home' data-aos="fade-up">
        Repose sur une compréhension approfondie de vos besoins spécifiques et une analyse détaillée de vos processus actuels. Nous collaborons étroitement avec vous pour identifier les points à améliorer, les risques potentiels et les opportunités d’optimisation. Forts de notre expérience dans l’industrie des produits de la mer, nous sommes en mesure de vous proposer des solutions sur mesure, adaptées aux exigences strictes de votre secteur.
        </p>

        
        <div className='doctor_bg'></div>




        <h1 data-aos="fade-up" className='title_conseil_2 text_10_home'>Accompagnement des Exportateurs et Investisseurs</h1>

        <p data-aos="fade-up" className='title_p_conseil_2 text_11_home'>
          Grâce à notre vaste expérience et à notre excellente réputation dans le domaine, nous offrons également un accompagnement spécialisé pour les exportateurs et les investisseurs souhaitant établir leurs projets ou développer leurs investissements dans le secteur des produits de la mer. Nous vous aidons à naviguer dans les complexités réglementaires, à identifier les opportunités de marché et à mettre en place des stratégies efficaces pour maximiser le retour sur investissement. Notre connaissance approfondie du marché local et international vous garantit un soutien précieux tout au long de votre parcours.
        </p>

        <div className='boat_bg_1'></div>


        <br/>
        <p data-aos="fade-up" className='title_p_conseil_2 text_12_home'>
        Nos services de conseil s’étendent de l’optimisation des processus de production à la gestion des risques, en passant par la formation de vos équipes aux nouvelles pratiques de contrôle qualité. Chaque recommandation que nous faisons est fondée sur des données précises et une expérience concrète, vous assurant des résultats tangibles et durables.
        </p>

        <div className='boat_bg_2'></div>


        <h1 data-aos="fade-up" className='title_conseil_2 text_13_home'>Accompagnement personnalisé</h1>

        <p data-aos="fade-up" className='title_p_conseil_2 text_14_home'>
          En choisissant Total Quality Solution, vous bénéficiez également d’un accompagnement personnalisé et d’un suivi continu. Nous ajustons nos services en fonction de l’évolution de vos besoins, en vous assurant que chaque projet est mené à bien avec succès. Faire appel à Total Quality Solution pour vos besoins en conseil et accompagnement, c’est choisir un partenaire engagé dans votre réussite. Contactez-nous dès aujourd’hui pour discuter de vos défis et découvrir comment nous pouvons vous aider à les surmonter avec des solutions sur mesure, adaptées à vos objectifs et à vos ambitions.
        </p>

        <br/>
        <br/>



      </div>




      
    </div>

    </>
  )
}

export default Home